import { defineStore } from "pinia";
import axios from "~/javascript/backend";
import { useApiPathStore } from "~/javascript/stores/api_paths";
import { useWebsiteStore } from "~/javascript/stores/websites";

const DEFAULT_STATE = {
  currentPurchase: {},
};

export const usePurchaseStore = defineStore({
  id: "purchases",

  state: () => ({
    ...structuredClone(DEFAULT_STATE),
  }),

  getters: {
    apiPathStore() {
      return useApiPathStore();
    },

    currentWebsite() {
      return this.websiteStore.currentWebsite;
    },

    purchasesPath() {
      return this.apiPathStore.purchasesPath;
    },

    websiteStore() {
      return useWebsiteStore();
    },
  },

  actions: {
    resetState() {
      this.$state = structuredClone(DEFAULT_STATE);
    },

    setCurrentPurchase(purchase) {
      this.currentPurchase = purchase;
    },

    async loadPurchase(id) {
      return new Promise((resolve) => {
        axios.get(`${this.purchasesPath}/${id}`).then((response) => {
          this.setCurrentPurchase(response.data);
          resolve();
        });
      });
    },
  },
});

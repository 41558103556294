<script setup>
import { ref } from "vue";

let messages = [];
const visible = ref(false);
const cssClasses = ref("alert");

Emitter.on("flashMessage", function (data) {
  if (data.messages && data.messages.length) {
    messages = data.messages;
  } else {
    messages = [data.message];
  }
  if (data.classes && data.classes.length > 0) {
    cssClasses.value = data.classes + " alert";
  }
  visible.value = true;

  let timeout = 5000;
  if (data.timeout > 0) {
    timeout = data.timeout * 1000;
  }
  let showTimeout = true;
  if (data.timeout === 0) {
    showTimeout = false;
  }
  if (showTimeout) {
    setTimeout(() => {
      visible.value = false;
    }, timeout);
  }
});
</script>

<template>
  <div v-if="visible" :class="cssClasses" role="alert">
    <div v-if="messages.length > 1">
      <ul class="mb-0">
        <li v-for="(message, i) in messages" :key="`${message}-${i}`">
          {{ message }}
        </li>
      </ul>
    </div>
    <div v-else>{{ messages[0] }}</div>
  </div>
</template>

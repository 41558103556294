import { defineStore } from "pinia";
import axios from "~/javascript/backend";
import router from "~/javascript/router";
import { emitSuccess } from "~/javascript/utilities";

const LOCAL_STORAGE_KEY = "current_workspace";

const DEFAULT_STATE = {
  workspace: {},
  workspaces: [],
  currentWorkspace: {},
};

export const useWorkspaceStore = defineStore({
  id: "workspaces",

  state: () => ({
    ...structuredClone(DEFAULT_STATE),
    currentWorkspace: JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)),
  }),

  actions: {
    resetState() {
      this.$state = structuredClone(DEFAULT_STATE);
    },

    loadUserWorkspaces() {
      axios.get("/workspaces").then((response) => {
        this.workspaces = response.data;
      });
    },

    async loadWorkspace(id) {
      return new Promise((resolve) => {
        axios.get(`workspaces/${id}`).then((response) => {
          this.setCurrentWorkspace(response.data);
          resolve();
        });
      });
    },

    joinWorkspaceByEmailDomain(workspaceId) {
      const data = { join: true };
      axios.put("/workspaces_join/" + workspaceId, data).then((response) => {
        router.push("/dashboard");
      });
    },

    createWorkspace(name, allowJoin) {
      const data = {
        workspace: {
          name: name,
          allow_join_by_email_domain: allowJoin.value,
        },
      };
      axios.post("/workspaces", data).then((response) => {
        this.setCurrentWorkspace(response.data);
      });
    },

    sendInvites(emails, workspaceId) {
      const data = {
        emails: emails,
        workspace_id: workspaceId,
      };
      axios.post("/workspaces/send_invites", data).then((response) => {
        Emitter.emit("inviteSent");
        emitSuccess("Invites sent");
      });
    },

    setCurrentWorkspace(workspace) {
      this.currentWorkspace = workspace;
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(workspace));
    },
  },
});

import { useOrgLocationStore } from "~/javascript/stores/org_locations";

const OrgLocationsCreate = () =>
  import("~/javascript/views/org_locations/OrgLocationsCreate.vue");
const OrgLocationsEdit = () =>
  import("~/javascript/views/org_locations/OrgLocationsEdit.vue");
const OrgLocationsIndex = () =>
  import("~/javascript/views/org_locations/OrgLocationsIndex.vue");
const OrgLocationsShow = () =>
  import("~/javascript/views/org_locations/OrgLocationsShow.vue");

const setCurrentOrgLocation = (to, from, next) => {
  const store = useOrgLocationStore();
  store.loadOrgLocation(to.params.location_id);
  next();
};

export const orgLocationsRoutes = [
  {
    path: "locations",
    name: "Locations",
    component: OrgLocationsIndex,
  },
  {
    path: "locations/create",
    name: "New Location",
    component: OrgLocationsCreate,
  },
  {
    path: "locations/:location_id",
    name: "Location",
    beforeEnter: setCurrentOrgLocation,
    children: [
      {
        path: "",
        name: "Location Home",
        component: OrgLocationsShow,
      },
      {
        path: "edit",
        name: "Edit Location",
        component: OrgLocationsEdit,
      },
    ],
  },
];

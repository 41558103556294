import axios from "axios";
import { expireSession } from "~/javascript/helpers";

const deviseAxios = axios.create({
  baseURL: import.meta.env.VITE_DEVISE_URL,
});

const defaultAxios = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
});

const adminAxios = axios.create({
  baseURL: `${import.meta.env.VITE_API_URL}/admin`,
});

[adminAxios, deviseAxios, defaultAxios].forEach((instance) => {
  instance.defaults.headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  instance.interceptors.request.use(function (config) {
    if (localStorage.token) {
      config.headers.Authorization = "Bearer " + localStorage.token;
      if (localStorage.getItem("current_workspace")) {
        config.params = {
          ...config.params,
          workspace_identifier: JSON.parse(
            localStorage.getItem("current_workspace")
          ).identifier,
        };
      }
    }
    return config;
  });

  instance.interceptors.response.use(
    (response) => response,
    function (error) {
      if (error.response && error.response.status === 401) {
        expireSession();
        return error.response;
      } else {
        throw error;
      }
    }
  );
});

export default defaultAxios;
export { adminAxios, deviseAxios };

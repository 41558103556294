import { useMediaScheduleStore } from "~/javascript/stores/media_schedules";

const MediaSchedulesCreate = () =>
  import("~/javascript/views/media_schedules/MediaSchedulesCreate.vue");
const MediaSchedulesEdit = () => import("~/javascript/views/media_schedules/MediaSchedulesEdit.vue");
const MediaSchedulesIndex = () =>
  import("~/javascript/views/media_schedules/MediaSchedulesIndex.vue");
const MediaSchedulesShow = () => import("~/javascript/views/media_schedules/MediaSchedulesShow.vue");

const setCurrentMediaSchedule = (to, from, next) => {
  const store = useMediaScheduleStore();
  if (store.currentWebsite?.id) {
    store.loadMediaSchedule(to.params.media_schedule_id);
    next();
  } else {
    setTimeout(() => setCurrentMediaSchedule(to, from, next), 100);
  }
};

export const mediaSchedulesRoutes = [
  // TODO: guard these routes depending on module access
  {
    path: "media_schedules",
    name: "Media Schedules",
    children: [
      {
        path: "",
        component: MediaSchedulesIndex,
      },
      {
        path: "create",
        name: "New Media Schedule",
        component: MediaSchedulesCreate,
      },
      {
        path: ":media_schedule_id",
        name: "Media Schedule",
        beforeEnter: setCurrentMediaSchedule,
        children: [
          {
            path: "",
            name: "Media Schedule Home",
            component: MediaSchedulesShow,
          },
          {
            path: "edit",
            name: "Edit Media Schedule",
            component: MediaSchedulesEdit,
          },
        ],
      },
    ],
  },
];

import { Tooltip } from "bootstrap";

export const tooltip = {
  mounted(el) {
    new Tooltip(el);
  },
  beforeUnmount(el) {
    const tooltip = Tooltip.getInstance(el);
    tooltip.hide();
  },
};

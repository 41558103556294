import { useTargetZoneStore } from "~/javascript/stores/target_zones";

const TargetZonesCreate = () =>
  import("~/javascript/views/target_zones/TargetZonesCreate.vue");
const TargetZonesEdit = () => import("~/javascript/views/target_zones/TargetZonesEdit.vue");
const TargetZonesIndex = () =>
  import("~/javascript/views/target_zones/TargetZonesIndex.vue");
const TargetZonesShow = () => import("~/javascript/views/target_zones/TargetZonesShow.vue");

const setCurrentTargetZone = (to, from, next) => {
  const store = useTargetZoneStore();
  store.loadTargetZone(to.params.target_zone_id);
  next();
};

export const targetZonesRoutes = [
  // TODO: guard these routes depending on module access
  {
    path: "target_zones",
    name: "Target Zones",
    children: [
      {
        path: "",
        component: TargetZonesIndex,
      },
      {
        path: "create",
        name: "New Target Zone",
        component: TargetZonesCreate,
      },
      {
        path: ":target_zone_id",
        name: "Target Zone",
        beforeEnter: setCurrentTargetZone,
        children: [
          {
            path: "",
            name: "Target Zone Home",
            component: TargetZonesShow,
          },
          {
            path: "edit",
            name: "Edit Target Zone",
            component: TargetZonesEdit,
          },
        ],
      },
    ],
  },
];

import { defineStore } from "pinia";
import axios from "~/javascript/backend";
import router from "~/javascript/router";
import { usePathStore } from "~/javascript/stores/paths";
import { emitError, emitSuccess } from "~/javascript/utilities";

const LOCAL_STORAGE_KEY = "current_org";
const ORGS_PATH = "/organizations";

const DEFAULT_STATE = {
  organizations: [],
  currentOrg: {},
  showOrg: {},
};

export const useOrgStore = defineStore({
  id: "organizations",

  state: () => ({
    ...structuredClone(DEFAULT_STATE),
    currentOrg: JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)),
  }),

  actions: {
    resetState() {
      this.$state = structuredClone(DEFAULT_STATE);
    },

    setCurrentOrg(org) {
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(org));
      this.currentOrg = org;
    },

    async loadOrg(id) {
      return new Promise((resolve) => {
        axios.get(`${ORGS_PATH}/${id}`).then((response) => {
          this.showOrg = response.data;
          this.setCurrentOrg(response.data);
          resolve();
        });
      });
    },

    async loadByIdentifier(identifier) {
      return new Promise((resolve) => {
        axios
          .get(`/organizations/by_identifier/${identifier}`)
          .then((response) => {
            this.setCurrentOrg(response.data);
            resolve();
          });
      });
    },

    loadOrgs() {
      axios.get(ORGS_PATH).then((response) => {
        this.organizations = response.data;
      });
    },

    update(org) {
      const orgObj = {
        name: org.name,
        identifier: org.identifier,
      };
      axios.put(`${ORGS_PATH}/${org.id}`, orgObj).then((response) => {
        this.showOrg = response.data;
        emitSuccess("Organization updated");
      });
    },

    createOrg({ name, identifier, workspaceId }) {
      const pathStore = usePathStore();
      const body = {
        organization: {
          name,
          identifier,
        },
        workspace_id: workspaceId,
      };
      axios
        .post(ORGS_PATH, body)
        .then((response) => {
          this.organizations.push(response.data);
          router.push(`${pathStore.orgEditPath(response.data.id)}`);
          emitSuccess("Organization created");
        })
        .catch(function (error) {
          emitError(error.response.data.errors);
        });
    },
  },
});

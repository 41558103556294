import {
  orgsNestedRoutes,
  EDIT_ORG,
} from "~/javascript/router/orgs_nested_routes";
import { useOrgStore } from "~/javascript/stores/organizations";

const OrgsCreate = () =>
  import("~/javascript/views/organizations/OrgsCreate.vue");
const OrgsIndex = () =>
  import("~/javascript/views/organizations/OrgsIndex.vue");
const OrgsUpload = () =>
  import("~/javascript/views/organizations/OrgsUpload.vue");

const setCurrentOrg = (to, from, next) => {
  const store = useOrgStore();

  if (to.name === EDIT_ORG) {
    store.loadOrg(to.params.org_id);
  } else {
    store.loadByIdentifier(to.params.org_id);
  }
  next();
};

export const orgsRoutes = [
  {
    path: "orgs",
    name: "Orgs",
    children: [
      {
        path: "",
        component: OrgsIndex,
      },
      {
        path: "create",
        name: "Create Org",
        meta: { subtitle: "Create a new org" },
        component: OrgsCreate,
      },
      {
        path: "upload",
        name: "Upload Orgs",
        meta: { subtitle: "Upload Orgs by CSV" },
        component: OrgsUpload,
      },
      {
        path: ":org_id",
        name: "Organization",
        beforeEnter: setCurrentOrg,
        children: orgsNestedRoutes,
      },
    ],
  },
];

<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import { useContactStore } from "~/javascript/stores/contacts";
import { useMediaScheduleStore } from "~/javascript/stores/media_schedules";
import { useOrgStore } from "~/javascript/stores/organizations";
import { useOrgLocationStore } from "~/javascript/stores/org_locations";
import { usePathStore } from "~/javascript/stores/paths";
import { useTargetZipStore } from "~/javascript/stores/target_zips";
import { useTargetZoneStore } from "~/javascript/stores/target_zones";
import { useTrackingTagStore } from "~/javascript/stores/tracking_tags";
import { useWebsiteStore } from "~/javascript/stores/websites";
import { useWorkspaceStore } from "~/javascript/stores/workspaces";

// hooks
const contactStore = useContactStore();
const mediaScheduleStore = useMediaScheduleStore();
const orgStore = useOrgStore();
const orgLocationStore = useOrgLocationStore();
const pathStore = usePathStore();
const targetZipStore = useTargetZipStore();
const targetZoneStore = useTargetZoneStore();
const trackingTagStore = useTrackingTagStore();
const websiteStore = useWebsiteStore();
const workspaceStore = useWorkspaceStore();
const route = useRoute();

// computed

const currentSite = computed(() => websiteStore.currentWebsite);

const paths = computed(() =>
  [...route.matched]
    .slice(0, -1)
    .filter((p) => !!p.name)
    .map((p) => {
      if (p.name === "Website Home") return null;
      if (p.name === "Organization") {
        return {
          name: orgStore.currentOrg?.name,
          path: pathStore.orgPath(),
        };
      }
      if (p.name === "Website") {
        return {
          name: currentSite.value?.domain,
          path: pathStore.websitePath(),
        };
      }
      if (p.name === "Location") {
        return {
          name: "Locations",
          path: pathStore.orgLocationsPath,
        };
      }

      const str = p.path
        .replace(":workspace_identifier", workspaceStore.currentWorkspace?.identifier)
        .replace(":org_id", orgStore.currentOrg?.identifier)
        .replace(":website_id", websiteStore.currentWebsite?.id)
        .replace(":tracking_tag_id", trackingTagStore.currentTrackingTag?.id)
        .replace(":contact_id", contactStore.currentContact?.id)
        .replace(":media_schedule_id", mediaScheduleStore.currentMediaSchedule?.id)
        .replace(":target_zip_id", targetZipStore.currentTargetZip?.id)
        .replace(":target_zone_id", targetZoneStore.currentTargetZone?.id);

      return { ...p, path: str };
    })
);

const routeName = computed(() => {
  let name;
  switch (route.name) {
    case "Website Home":
      name = `${currentSite.value?.name} - ${currentSite.value?.domain}`;
      break;
    case "Location Home":
      name = orgLocationStore.currentOrgLocation?.name;
      break;
    case "Organization Home":
      name = orgStore.currentOrg?.name;
      break;
    case "Target Zip Home":
      name = targetZipStore.currentTargetZip?.zip;
      break;
    case "Target Zone Home":
      name = targetZoneStore.currentTargetZone?.name;
      break;
    default:
      name = route.name;
      break;
  }
  return name;
});

const subtitle = computed(() => route.meta.subtitle);
</script>

<template>
  <div class="row">
    <div class="col-12 col-md-6 order-md-1 order-last">
      <h3>{{ routeName }}</h3>
      <p class="text-subtitle text-muted">
        {{ subtitle }}
      </p>
    </div>
    <div class="col-12 col-md-6 order-md-2 order-first">
      <nav
        aria-label="breadcrumb"
        class="breadcrumb-header float-start float-lg-end"
      >
        <ol class="breadcrumb">
          <li
            v-for="(pathObj, i) in paths"
            :key="`${pathObj.name}-${i}`"
            class="breadcrumb-item"
          >
            <router-link :to="pathObj.path">{{ pathObj.name }}</router-link>
          </li>
          <li
            v-if="route.name"
            class="breadcrumb-item active"
            aria-current="page"
          >
            {{ route.name }}
          </li>
        </ol>
      </nav>
    </div>
  </div>
</template>

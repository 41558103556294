import { useAdminSubscriptionPlanStore } from "~/javascript/stores/admin/subscription_plans";

const SubscriptionPlansCreate = () =>
  import(
    "~/javascript/views/admin/subscription_plans/SubscriptionPlansCreate.vue"
  );
const SubscriptionPlansEdit = () =>
  import(
    "~/javascript/views/admin/subscription_plans/SubscriptionPlansEdit.vue"
  );
const SubscriptionPlansIndex = () =>
  import(
    "~/javascript/views/admin/subscription_plans/SubscriptionPlansIndex.vue"
  );
const SubscriptionPlansShow = () =>
  import(
    "~/javascript/views/admin/subscription_plans/SubscriptionPlansShow.vue"
  );

const setCurrentPlan = (to, from, next) => {
  const store = useAdminSubscriptionPlanStore();
  store.loadSubscriptionPlan(to.params.subscription_id);
  next();
};

export const subscriptionPlanRoutes = [
  {
    path: "subscription_plans",
    name: "Subscription Plans",
    component: SubscriptionPlansIndex,
  },
  {
    path: "subscription_plans/create",
    name: "New Subscription Plan",
    component: SubscriptionPlansCreate,
  },
  {
    path: "subscription_plans/:subscription_id",
    name: "Subscription Plan",
    beforeEnter: setCurrentPlan,
    children: [
      {
        path: "",
        name: "Subscription Plan Home",
        component: SubscriptionPlansShow,
      },
      {
        path: "edit",
        name: "Edit Subscription Plan",
        component: SubscriptionPlansEdit,
      },
    ],
  },
];

// helper functions that touch the vue/pinia layer

import { useOrgStore } from "~/javascript/stores/organizations";
import { useUserStore } from "~/javascript/stores/users";
import { useWorkspaceStore } from "~/javascript/stores/workspaces";

export const clearStores = () => {
  const orgStore = useOrgStore();
  const userStore = useUserStore();
  const workspaceStore = useWorkspaceStore();

  orgStore.resetState();
  userStore.resetState();
  workspaceStore.resetState();
};

export const clearLocalStorage = () => {
  localStorage.removeItem("current_org");
  localStorage.removeItem("current_user");
  localStorage.removeItem("current_workspace");
  localStorage.removeItem("token");
};

export const expireSession = () => {
  clearLocalStorage();
  clearStores();
  localStorage.setItem("return_url", window.location.pathname);
  if (window.location.href.indexOf("login") < 0) {
    window.location.href = "/login";
  }
};

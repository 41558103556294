import { useTrackingTagStore } from "~/javascript/stores/tracking_tags";

const RouterViewTemplate = () =>
  import("~/javascript/components/RouterViewTemplate.vue");

const TrackingTagsCreate = () =>
  import("~/javascript/views/websites/tracking_tags/TrackingTagsCreate.vue");
const TrackingTagsEdit = () =>
  import("~/javascript/views/websites/tracking_tags/TrackingTagsEdit.vue");
const TrackingTagsIndex = () =>
  import("~/javascript/views/websites/tracking_tags/TrackingTagsIndex.vue");
const TrackingTagsShow = () =>
  import("~/javascript/views/websites/tracking_tags/TrackingTagsShow.vue");

const setCurrentTag = (to, from, next) => {
  const store = useTrackingTagStore();
  if (store.currentWebsite?.id) {
    store.loadTrackingTag(to.params.tracking_tag_id);
    next();
  } else {
    setTimeout(() => setCurrentTag(to, from, next), 100);
  }
};

export const trackingTagsRoutes = [
  {
    path: "tracking_tags",
    name: "Tracking Tags",
    component: RouterViewTemplate,
    children: [
      {
        path: "",
        component: TrackingTagsIndex,
      },
      {
        path: "create",
        name: "New Tracking Tag",
        component: TrackingTagsCreate,
      },
      {
        path: ":tracking_tag_id",
        name: "Tracking Tag",
        beforeEnter: setCurrentTag,
        children: [
          {
            path: "",
            component: TrackingTagsShow,
          },
          {
            path: "edit",
            name: "Edit Tag",
            component: TrackingTagsEdit,
          },
        ],
      },
    ],
  },
];

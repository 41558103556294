import { useWebsiteStore } from "~/javascript/stores/websites";
import { leadsRoutes } from "~/javascript/router/websites/leads_routes";
import { mediaSchedulesRoutes } from "~/javascript/router/media_schedules";
import { purchasesRoutes } from "~/javascript/router/websites/purchases_routes";
import { reportsRoutes } from "~/javascript/router/websites/reports_routes";
import { trackingTagsRoutes } from "~/javascript/router/websites/tracking_tags";

const WebsitesCreate = () =>
  import("~/javascript/views/websites/WebsitesCreate.vue");
const WebsitesEdit = () =>
  import("~/javascript/views/websites/WebsitesEdit.vue");
const WebsitesIndex = () =>
  import("~/javascript/views/websites/WebsitesIndex.vue");
const WebsitesShow = () =>
  import("~/javascript/views/websites/WebsitesShow.vue");


const setCurrentSite = (to, from, next) => {
  const store = useWebsiteStore();
  store.loadWebsite(to.params.website_id);
  next();
};

export const websitesRoutes = [
  {
    path: "websites",
    name: "Websites",
    children: [
      {
        path: "",
        component: WebsitesIndex,
      },
      {
        path: "create",
        name: "New Website",
        component: WebsitesCreate,
      },
      {
        path: ":website_id",
        name: "Website",
        beforeEnter: setCurrentSite,
        children: [
          {
            path: "",
            name: "Website Home",
            component: WebsitesShow,
          },
          {
            path: "edit",
            name: "Edit Website",
            component: WebsitesEdit,
          },
          ...leadsRoutes,
          ...mediaSchedulesRoutes,
          ...purchasesRoutes,
          ...reportsRoutes,
          ...trackingTagsRoutes,
        ],
      },
    ],
  },
];

import { useUserStore } from "~/javascript/stores/users";

const UsersCreate = () => import("~/javascript/views/users/UsersCreate.vue");
const UsersEdit = () => import("~/javascript/views/users/UsersEdit.vue");
const UsersIndex = () => import("~/javascript/views/users/UsersIndex.vue");
const UsersUpload = () => import("~/javascript/views/users/UsersUpload.vue");

const setShownUser = (to, from, next) => {
  const store = useUserStore();
  store.loadUser(to.params.user_id);
  next();
};

export const userRoutes = [
  {
    path: "users",
    name: "Users",
    children: [
      {
        path: "",
        component: UsersIndex,
      },
      {
        path: "create",
        name: "Create User",
        meta: { subtitle: "Create a new user" },
        component: UsersCreate,
      },
      {
        path: "upload",
        name: "Upload Users",
        meta: { subtitle: "Upload Users by CSV" },
        component: UsersUpload,
      },
      {
        path: ":user_id/edit",
        name: "Edit User",
        beforeEnter: setShownUser,
        component: UsersEdit,
      },
    ],
  },
];

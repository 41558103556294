import { createRouter, createWebHistory } from "vue-router";

import { adminRoutes } from "~/javascript/router/admin/admin_routes";
import { authRoutes } from "~/javascript/router/auth_routes";
import { workspaceSetupRoutes } from "~/javascript/router/workspace_setup_routes";
import { workspaceGeneralRoutes } from "~/javascript/router/workspace_general_routes";
import { orgsRoutes } from "~/javascript/router/orgs_routes";
import { targetZonesRoutes } from "~/javascript/router/target_zones_routes";
import { targetZipsRoutes } from "~/javascript/router/target_zips_routes";
import { userRoutes } from "~/javascript/router/user_routes";
import { clearLocalStorage, clearStores } from "~/javascript/helpers";
import { useAuthStore } from "~/javascript/stores/auth";
import { useWorkspaceStore } from "~/javascript/stores/workspaces";

// route level code-splitting
// this generates a separate chunk (Map.[hash].js) for this route
// which is lazy-loaded when the route is visited.
const AdminApplicationView = () =>
  import("~/javascript/views/admin/AdminApplicationView.vue");
const ApplicationView = () => import("~/javascript/views/ApplicationView.vue");
const LayoutView = () => import("~/javascript/views/workspace/LayoutView.vue");
const currentUser = JSON.parse(localStorage.getItem("current_user"));

const authUser = (to, from, next) => {
  if (!localStorage.token && !(window.location.pathname == "/login")) {
    clearLocalStorage();
    clearStores();

    const authStore = useAuthStore();
    authStore.returnUrl = to.fullPath;

    next({ name: "Login" });
    return;
  }
  if (to.path.includes("/admin") && !currentUser.global_admin) {
    next({ name: "Login" });
    return;
  }
  if (to.path == "/admin" && currentUser.global_admin) {
    next({ name: "Admin Dashboard" });
    return;
  }
  next();
};

const setCurrentWorkspace = (to, from, next) => {
  const store = useWorkspaceStore();
  if (store.currentWorkspace.identifier !== to.params.workspace_identifier) {
    store.loadWorkspace(to.params.workspace_identifier);
  }
  next();
};

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      redirect: "/login",
    },
    ...authRoutes,
    {
      path: "/workspace",
      name: "Workspace",
      component: LayoutView,
      beforeEnter: authUser,
      children: workspaceSetupRoutes,
    },
    {
      path: "/admin",
      name: "Admin",
      component: AdminApplicationView,
      beforeEnter: authUser,
      children: adminRoutes,
    },
    {
      path: "/:workspace_identifier",
      component: ApplicationView,
      beforeEnter: [authUser, setCurrentWorkspace],
      children: [
        ...orgsRoutes,
        ...targetZipsRoutes,
        ...targetZonesRoutes,
        ...userRoutes,
        ...workspaceGeneralRoutes,
        // TODO: workspace settings
        // TODO: departments, boards, etc.
      ],
    },
  ],
});

router.afterEach((to, from, failure) => {
  if (failure) {
    console.log("router failure: ");
    console.log(to, from, failure);
  }
});

export default router;

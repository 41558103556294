import { defineStore } from "pinia";
import axios from "~/javascript/backend";
import router from "~/javascript/router";
import { useApiPathStore } from "~/javascript/stores/api_paths";
import { usePathStore } from "~/javascript/stores/paths";
import { emitError, emitSuccess } from "~/javascript/utilities";

const DEFAULT_STATE = {
  currentTargetZone: {},
  targetZoneLoading: false,
};

export const useTargetZoneStore = defineStore({
  id: "target_zones",

  state: () => ({
    ...structuredClone(DEFAULT_STATE),
  }),

  getters: {
    pathStore() {
      const store = usePathStore();
      return store;
    },

    targetZonesPath() {
      return useApiPathStore().targetZonesPath;
    },
  },

  actions: {
    resetState() {
      this.$state = structuredClone(DEFAULT_STATE);
    },

    setCurrentTargetZone(zone) {
      this.currentTargetZone = zone;
    },

    fetchTargetZones() {
      return axios.get(this.targetZonesPath).then((response) => {
        return response.data;
      });
    },

    async loadTargetZone(id) {
      return new Promise((resolve) => {
        this.targetZoneLoading = true;
        axios.get(`${this.targetZonesPath}/${id}`).then((response) => {
          this.setCurrentTargetZone(response.data);
          this.targetZoneLoading = false;
          resolve();
        });
      });
    },

    update({ zoneObj, id }) {
      axios
        .put(`${this.targetZonesPath}/${id}`, zoneObj)
        .then((response) => {
          this.setCurrentTargetZone(response.data);
          emitSuccess("Zone updated");
        })
        .catch((error) => {
          emitError(error.response.data.errors);
        });
    },

    create(zoneObj) {
      axios
        .post(this.targetZonesPath, zoneObj, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.setCurrentTargetZone(response.data);
          router.push(`${this.pathStore.targetZonePath(response.data.id)}`);
          emitSuccess("Zone created");
        })
        .catch((error) => {
          emitError(error);
        });
    },

    delete(id) {
      axios
        .delete(`${this.targetZonesPath}/${id}`)
        .then((response) => {
          router.push(this.pathStore.targetZonesPath);
          emitSuccess(response.data.message);
        })
        .catch((error) => {
          emitError(error.response.data.errors);
        });
    },
  },
});

import { defineStore } from "pinia";
import { deviseAxios } from "~/javascript/backend";
import router from "~/javascript/router";
import { clearLocalStorage, clearStores } from "~/javascript/helpers";
import { emitError, emitSuccess } from "~/javascript/utilities";

export const useAuthStore = defineStore({
  id: "auth",
  state: () => ({
    // initialize state from local storage to enable user to stay logged in
    currentUser: JSON.parse(localStorage.getItem("current_user")),
    returnUrl: null,
  }),

  getters: {
    getCurrentUser(state) {
      return state.currentUser;
    },

    getReturnUrl() {
      const url = this.returnUrl || localStorage.getItem("return_url");

      if (url.includes("undefined") || url.includes("null")) return;

      return url
    },
  },

  actions: {
    resetPassword(data) {
      const userData = { user: data };
      deviseAxios.put("/users/password", userData).then(function (response) {
        emitSuccess("Password updated. Redirecting to login page ... ");
        setTimeout(function () {
          router.push("/dashboard");
        }, 3000);
      });
    },

    forgotPassword(email) {
      const data = {
        user: { email: email },
      };
      deviseAxios.post("/users/password", data).then(function (response) {
        emitSuccess("Check your email for the reset password instructions");
      });
    },

    setCurrentUser(response) {
      const store = useAuthStore();
      const token = response.headers.get("Authorization").split(" ")[1];
      localStorage.setItem("token", token);
      store.updateCurrentUser(response.data);
    },

    register(userData) {
      const userObject = { user: userData };
      deviseAxios
        .post("/users", userObject)
        .then((response) => {
          if (response && response.status === 200) {
            router.push("/verifyemail");
          }
        })
        .catch(function (error) {
          emitError(error.response.data.errors);
        });
    },

    verifyEmail(token) {
      const url = "/users/confirmation?confirmation_token=" + token;
      deviseAxios.get(url).then((response) => {
        if (response && response.status === 200) {
          const store = useAuthStore();
          store.setCurrentUser(response);
          router.push("/workspace/setup");
        }
      });
    },

    login(userData) {
      const userObject = { user: userData };

      deviseAxios.post("/users/login", userObject).then((response) => {
        if (response && response.status === 200) {
          const store = useAuthStore();
          store.setCurrentUser(response);

          if (this.getReturnUrl) {
            const route = router.resolve(this.getReturnUrl);
            const workspaceId = route.params.workspace_identifier;
            const result = workspaceId
              ? `/workspace/preload/${workspaceId}`
              : "/workspace/select";
            router.push(result);
          } else {
            router.push("/workspace/select");
          }
        } else {
          emitError(response.data.errors);
        }
      });
    },

    updateCurrentUser(userData) {
      userData.email_domain = userData.email.split("@")[1];
      localStorage.setItem("current_user", JSON.stringify(userData));
      this.currentUser = userData;
    },

    private() {
      deviseAxios.get("/private").then(function (response) {
        console.log(response.data);
      });
    },

    logout() {
      var self = this;
      deviseAxios
        .delete("/users/logout")
        .then(function (response) {
          setTimeout(function () {
            clearLocalStorage();
            clearStores();
            self.currentUser = null;
          }, 200);
          router.push("/login");
        })
        .catch(function (error) {
          console.log(error?.response?.data?.error);
        });
    },
  },
});

const SourceMediumReport = () =>
  import("~/javascript/views/websites/reports/SourceMediumReport.vue");

export const reportsRoutes = [
  {
    path: "reports/source_medium",
    name: "Source Medium Report",
    component: SourceMediumReport,
  },
];

import { useContactStore } from "~/javascript/stores/contacts";

const ContactsCreate = () =>
  import("~/javascript/views/contacts/ContactsCreate.vue");
const ContactsEdit = () =>
  import("~/javascript/views/contacts/ContactsEdit.vue");
const ContactsIndex = () =>
  import("~/javascript/views/contacts/ContactsIndex.vue");
const ContactsShow = () =>
  import("~/javascript/views/contacts/ContactsShow.vue");

const ContactsUpload = () =>
  import("~/javascript/views/contacts/ContactsUpload.vue");

const setCurrentContact = (to, from, next) => {
  const store = useContactStore();
  store.loadContact(to.params.contact_id);
  next();
};

// this is the way
// TODO: convert other route sets to this pattern
// so we don't have as much weird monkeypatching in breadcrumbs.vue
export const contactsRoutes = [
  {
    path: "contacts",
    name: "Contacts",
    children: [
      {
        path: "",
        component: ContactsIndex,
      },
      {
        path: "create",
        name: "New Contact",
        component: ContactsCreate,
      },
      {
        path: "upload",
        name: "Bulk Upload Contacts",
        component: ContactsUpload,
      },
      {
        path: ":contact_id",
        name: "Contact",
        beforeEnter: setCurrentContact,
        children: [
          {
            path: "",
            name: "Contact Home",
            component: ContactsShow,
          },
          {
            path: "edit",
            name: "Edit Contact",
            component: ContactsEdit,
          },
        ],
      },
    ],
  },
];

import { contactsRoutes } from "~/javascript/router/contacts_routes";
import { orgLocationsRoutes } from "~/javascript/router/org_locations_routes";
import { websitesRoutes } from "~/javascript/router/websites/websites_routes";

export const EDIT_ORG = "Edit Organization";

const OrgsEdit = () => import("~/javascript/views/organizations/OrgsEdit.vue");
const OrgsShow = () => import("~/javascript/views/organizations/OrgsShow.vue");

export const orgsNestedRoutes = [
  {
    path: "edit",
    name: EDIT_ORG,
    meta: { subtitle: "Manage your organization" },
    component: OrgsEdit,
  },
  {
    path: "",
    name: "Organization Home",
    component: OrgsShow,
  },
  ...contactsRoutes,
  ...orgLocationsRoutes,
  ...websitesRoutes,
  // TODO: dept routes
];

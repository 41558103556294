const CreateWorkspaceView = () =>
  import("~/javascript/views/workspace/CreateWorkspaceView.vue");
const InvitePeopleWorkspaceView = () =>
  import("~/javascript/views/workspace/InvitePeopleWorkspaceView.vue");
const SelectWorkspaceView = () =>
  import("~/javascript/views/workspace/SelectWorkspaceView.vue");
const LoadWorkspaceView = () =>
  import("~/javascript/views/workspace/LoadWorkspaceView.vue");
const SetupWorkspaceView = () =>
  import("~/javascript/views/workspace/SetupWorkspaceView.vue");

export const workspaceSetupRoutes = [
  {
    path: "setup",
    name: "SetupWorkspace",
    component: SetupWorkspaceView,
  },
  {
    path: "create",
    name: "CreateWorkspace",
    component: CreateWorkspaceView,
  },
  {
    path: "invitepeople",
    name: "InvitePeopleWorkspace",
    component: InvitePeopleWorkspaceView,
  },
  {
    path: "select",
    name: "SelectWorkspaceView",
    component: SelectWorkspaceView,
  },
  {
    path: "preload/:workspace_identifier",
    name: "LoadWorkspaceView",
    component: LoadWorkspaceView,
  },
];

import { subscriptionPlanRoutes } from "~/javascript/router/admin/subscription_plans.js";

const AdminDashboard = () =>
  import("~/javascript/views/admin/AdminDashboard.vue");

export const adminRoutes = [
  {
    path: "dashboard",
    name: "Admin Dashboard",
    component: AdminDashboard,
  },
  ...subscriptionPlanRoutes,
];
